export const alpha3CountryCodes = new Map();

alpha3CountryCodes.set('ABW', 'Aruba');
alpha3CountryCodes.set('AFG', 'Afghanistan');
alpha3CountryCodes.set('AGO', 'Angola');
alpha3CountryCodes.set('AIA', 'Anguilla');
alpha3CountryCodes.set('ALA', 'Eland');
alpha3CountryCodes.set('ALB', 'Albania');
alpha3CountryCodes.set('AND', 'Andorra');
alpha3CountryCodes.set('ARE', 'United');
alpha3CountryCodes.set('ARG', 'Argentina');
alpha3CountryCodes.set('ARM', 'Armenia');
alpha3CountryCodes.set('ASM', 'American');
alpha3CountryCodes.set('ATA', 'Antarctica');
alpha3CountryCodes.set('ATF', 'French');
alpha3CountryCodes.set('ATG', 'Antigua');
alpha3CountryCodes.set('AUS', 'Australia');
alpha3CountryCodes.set('AUT', 'Austria');
alpha3CountryCodes.set('AZE', 'Azerbaijan');
alpha3CountryCodes.set('BDI', 'Burundi');
alpha3CountryCodes.set('BEL', 'Belgium');
alpha3CountryCodes.set('BEN', 'Benin');
alpha3CountryCodes.set('BES', 'Bonaire');
alpha3CountryCodes.set('BFA', 'Burkina');
alpha3CountryCodes.set('BGD', 'Bangladesh');
alpha3CountryCodes.set('BGR', 'Bulgaria');
alpha3CountryCodes.set('BHR', 'Bahrain');
alpha3CountryCodes.set('BHS', 'Bahamas');
alpha3CountryCodes.set('BIH', 'Bosnia');
alpha3CountryCodes.set('BLM', 'Saint');
alpha3CountryCodes.set('BLR', 'Belarus');
alpha3CountryCodes.set('BLZ', 'Belize');
alpha3CountryCodes.set('BMU', 'Bermuda');
alpha3CountryCodes.set('BOL', 'Bolivia');
alpha3CountryCodes.set('BRA', 'Brazil');
alpha3CountryCodes.set('BRB', 'Barbados');
alpha3CountryCodes.set('BRN', 'Brunei');
alpha3CountryCodes.set('BTN', 'Bhutan');
alpha3CountryCodes.set('BVT', 'Bouvet');
alpha3CountryCodes.set('BWA', 'Botswana');
alpha3CountryCodes.set('CAF', 'Central');
alpha3CountryCodes.set('CAN', 'Canada');
alpha3CountryCodes.set('CCK', 'Cocos');
alpha3CountryCodes.set('CHE', 'Switzerland');
alpha3CountryCodes.set('CHL', 'Chile');
alpha3CountryCodes.set('CHN', 'China');
alpha3CountryCodes.set('CIV', 'Ctte');
alpha3CountryCodes.set('CMR', 'Cameroon');
alpha3CountryCodes.set('COD', 'Congo');
alpha3CountryCodes.set('COG', 'Congo');
alpha3CountryCodes.set('COK', 'Cook');
alpha3CountryCodes.set('COL', 'Colombia');
alpha3CountryCodes.set('COM', 'Comoros');
alpha3CountryCodes.set('CPV', 'Cabo');
alpha3CountryCodes.set('CRI', 'Costa');
alpha3CountryCodes.set('CUB', 'Cuba');
alpha3CountryCodes.set('CUW', 'Curagao');
alpha3CountryCodes.set('CXR', 'Christmas');
alpha3CountryCodes.set('CYM', 'Cayman');
alpha3CountryCodes.set('CYP', 'Cyprus');
alpha3CountryCodes.set('CZE', 'Czechia');
alpha3CountryCodes.set('DEU', 'Germany');
alpha3CountryCodes.set('DJI', 'Djibouti');
alpha3CountryCodes.set('DMA', 'Dominica');
alpha3CountryCodes.set('DNK', 'Denmark');
alpha3CountryCodes.set('DOM', 'Dominican');
alpha3CountryCodes.set('DZA', 'Algeria');
alpha3CountryCodes.set('ECU', 'Ecuador');
alpha3CountryCodes.set('EGY', 'Egypt');
alpha3CountryCodes.set('ERI', 'Eritrea');
alpha3CountryCodes.set('ESH', 'Western');
alpha3CountryCodes.set('ESP', 'Spain');
alpha3CountryCodes.set('EST', 'Estonia');
alpha3CountryCodes.set('ETH', 'Ethiopia');
alpha3CountryCodes.set('FIN', 'Finland');
alpha3CountryCodes.set('FJI', 'Fiji');
alpha3CountryCodes.set('FLK', 'Falkland');
alpha3CountryCodes.set('FRA', 'France');
alpha3CountryCodes.set('FRO', 'Faroe');
alpha3CountryCodes.set('FSM', 'Micronesia');
alpha3CountryCodes.set('GAB', 'Gabon');
alpha3CountryCodes.set('GBR', 'United');
alpha3CountryCodes.set('GEO', 'Georgia');
alpha3CountryCodes.set('GGY', 'Guernsey');
alpha3CountryCodes.set('GHA', 'Ghana');
alpha3CountryCodes.set('GIB', 'Gibraltar');
alpha3CountryCodes.set('GIN', 'Guinea');
alpha3CountryCodes.set('GLP', 'Guadeloupe');
alpha3CountryCodes.set('GMB', 'Gambia');
alpha3CountryCodes.set('GNB', 'Guinea-Bissau');
alpha3CountryCodes.set('GNQ', 'Equatorial');
alpha3CountryCodes.set('GRC', 'Greece');
alpha3CountryCodes.set('GRD', 'Grenada');
alpha3CountryCodes.set('GRL', 'Greenland');
alpha3CountryCodes.set('GTM', 'Guatemala');
alpha3CountryCodes.set('GUF', 'French');
alpha3CountryCodes.set('GUM', 'Guam');
alpha3CountryCodes.set('GUY', 'Guyana');
alpha3CountryCodes.set('HKG', 'Hong');
alpha3CountryCodes.set('HMD', 'Heard');
alpha3CountryCodes.set('HND', 'Honduras');
alpha3CountryCodes.set('HRV', 'Croatia');
alpha3CountryCodes.set('HTI', 'Haiti');
alpha3CountryCodes.set('HUN', 'Hungary');
alpha3CountryCodes.set('IDN', 'Indonesia');
alpha3CountryCodes.set('IMN', 'Isle');
alpha3CountryCodes.set('IND', 'India');
alpha3CountryCodes.set('IOT', 'British');
alpha3CountryCodes.set('IRL', 'Ireland');
alpha3CountryCodes.set('IRN', 'Iran');
alpha3CountryCodes.set('IRQ', 'Iraq');
alpha3CountryCodes.set('ISL', 'Iceland');
alpha3CountryCodes.set('ISR', 'Israel');
alpha3CountryCodes.set('ITA', 'Italy');
alpha3CountryCodes.set('JAM', 'Jamaica');
alpha3CountryCodes.set('JEY', 'Jersey');
alpha3CountryCodes.set('JOR', 'Jordan');
alpha3CountryCodes.set('JPN', 'Japan');
alpha3CountryCodes.set('KAZ', 'Kazakhstan');
alpha3CountryCodes.set('KEN', 'Kenya');
alpha3CountryCodes.set('KGZ', 'Kyrgyzstan');
alpha3CountryCodes.set('KHM', 'Cambodia');
alpha3CountryCodes.set('KIR', 'Kiribati');
alpha3CountryCodes.set('KNA', 'Saint');
alpha3CountryCodes.set('KOR', 'Korea');
alpha3CountryCodes.set('KWT', 'Kuwait');
alpha3CountryCodes.set('LAO', 'Lao');
alpha3CountryCodes.set('LBN', 'Lebanon');
alpha3CountryCodes.set('LBR', 'Liberia');
alpha3CountryCodes.set('LBY', 'Libya');
alpha3CountryCodes.set('LCA', 'Saint');
alpha3CountryCodes.set('LIE', 'Liechtenstein');
alpha3CountryCodes.set('LKA', 'Sri');
alpha3CountryCodes.set('LSO', 'Lesotho');
alpha3CountryCodes.set('LTU', 'Lithuania');
alpha3CountryCodes.set('LUX', 'Luxembourg');
alpha3CountryCodes.set('LVA', 'Latvia');
alpha3CountryCodes.set('MAC', 'Macao');
alpha3CountryCodes.set('MAF', 'Saint');
alpha3CountryCodes.set('MAR', 'Morocco');
alpha3CountryCodes.set('MCO', 'Monaco');
alpha3CountryCodes.set('MDA', 'Moldova');
alpha3CountryCodes.set('MDG', 'Madagascar');
alpha3CountryCodes.set('MDV', 'Maldives');
alpha3CountryCodes.set('MEX', 'Mexico');
alpha3CountryCodes.set('MHL', 'Marshall');
alpha3CountryCodes.set('MKD', 'Macedonia');
alpha3CountryCodes.set('MLI', 'Mali');
alpha3CountryCodes.set('MLT', 'Malta');
alpha3CountryCodes.set('MMR', 'Myanmar');
alpha3CountryCodes.set('MNE', 'Montenegro');
alpha3CountryCodes.set('MNG', 'Mongolia');
alpha3CountryCodes.set('MNP', 'Northern');
alpha3CountryCodes.set('MOZ', 'Mozambique');
alpha3CountryCodes.set('MRT', 'Mauritania');
alpha3CountryCodes.set('MSR', 'Montserrat');
alpha3CountryCodes.set('MTQ', 'Martinique');
alpha3CountryCodes.set('MUS', 'Mauritius');
alpha3CountryCodes.set('MWI', 'Malawi');
alpha3CountryCodes.set('MYS', 'Malaysia');
alpha3CountryCodes.set('MYT', 'Mayotte');
alpha3CountryCodes.set('NAM', 'Namibia');
alpha3CountryCodes.set('NCL', 'New');
alpha3CountryCodes.set('NER', 'Niger');
alpha3CountryCodes.set('NFK', 'Norfolk');
alpha3CountryCodes.set('NGA', 'Nigeria');
alpha3CountryCodes.set('NIC', 'Nicaragua');
alpha3CountryCodes.set('NIU', 'Niue');
alpha3CountryCodes.set('NLD', 'Netherlands');
alpha3CountryCodes.set('NOR', 'Norway');
alpha3CountryCodes.set('NPL', 'Nepal');
alpha3CountryCodes.set('NRU', 'Nauru');
alpha3CountryCodes.set('NZL', 'New');
alpha3CountryCodes.set('OMN', 'Oman');
alpha3CountryCodes.set('PAK', 'Pakistan');
alpha3CountryCodes.set('PAN', 'Panama');
alpha3CountryCodes.set('PCN', 'Pitcairn');
alpha3CountryCodes.set('PER', 'Peru');
alpha3CountryCodes.set('PHL', 'Philippines');
alpha3CountryCodes.set('PLW', 'Palau');
alpha3CountryCodes.set('PNG', 'Papua');
alpha3CountryCodes.set('POL', 'Poland');
alpha3CountryCodes.set('PRI', 'Puerto');
alpha3CountryCodes.set('PRK', 'Korea');
alpha3CountryCodes.set('PRT', 'Portugal');
alpha3CountryCodes.set('PRY', 'Paraguay');
alpha3CountryCodes.set('PSE', 'Palestine');
alpha3CountryCodes.set('PYF', 'French');
alpha3CountryCodes.set('QAT', 'Qatar');
alpha3CountryCodes.set('REU', 'Riunion');
alpha3CountryCodes.set('ROU', 'Romania');
alpha3CountryCodes.set('RUS', 'Russian');
alpha3CountryCodes.set('RWA', 'Rwanda');
alpha3CountryCodes.set('SAU', 'Saudi');
alpha3CountryCodes.set('SDN', 'Sudan');
alpha3CountryCodes.set('SEN', 'Senegal');
alpha3CountryCodes.set('SGP', 'Singapore');
alpha3CountryCodes.set('SGS', 'South');
alpha3CountryCodes.set('SHN', 'Saint');
alpha3CountryCodes.set('SJM', 'Svalbard');
alpha3CountryCodes.set('SLB', 'Solomon');
alpha3CountryCodes.set('SLE', 'Sierra');
alpha3CountryCodes.set('SLV', 'El');
alpha3CountryCodes.set('SMR', 'San');
alpha3CountryCodes.set('SOM', 'Somalia');
alpha3CountryCodes.set('SPM', 'Saint');
alpha3CountryCodes.set('SRB', 'Serbia');
alpha3CountryCodes.set('SSD', 'South');
alpha3CountryCodes.set('STP', 'Sao');
alpha3CountryCodes.set('SUR', 'Suriname');
alpha3CountryCodes.set('SVK', 'Slovakia');
alpha3CountryCodes.set('SVN', 'Slovenia');
alpha3CountryCodes.set('SWE', 'Sweden');
alpha3CountryCodes.set('SWZ', 'Swaziland');
alpha3CountryCodes.set('SXM', 'Sint');
alpha3CountryCodes.set('SYC', 'Seychelles');
alpha3CountryCodes.set('SYR', 'Syrian');
alpha3CountryCodes.set('TCA', 'Turks');
alpha3CountryCodes.set('TCD', 'Chad');
alpha3CountryCodes.set('TGO', 'Togo');
alpha3CountryCodes.set('THA', 'Thailand');
alpha3CountryCodes.set('TJK', 'Tajikistan');
alpha3CountryCodes.set('TKL', 'Tokelau');
alpha3CountryCodes.set('TKM', 'Turkmenistan');
alpha3CountryCodes.set('TLS', 'Timor-Leste');
alpha3CountryCodes.set('TON', 'Tonga');
alpha3CountryCodes.set('TTO', 'Trinidad');
alpha3CountryCodes.set('TUN', 'Tunisia');
alpha3CountryCodes.set('TUR', 'Turkey');
alpha3CountryCodes.set('TUV', 'Tuvalu');
alpha3CountryCodes.set('TWN', 'Taiwan');
alpha3CountryCodes.set('TZA', 'Tanzania');
alpha3CountryCodes.set('UGA', 'Uganda');
alpha3CountryCodes.set('UKR', 'Ukraine');
alpha3CountryCodes.set('UMI', 'United');
alpha3CountryCodes.set('URY', 'Uruguay');
alpha3CountryCodes.set('USA', 'United');
alpha3CountryCodes.set('UZB', 'Uzbekistan');
alpha3CountryCodes.set('VAT', 'Holy');
alpha3CountryCodes.set('VCT', 'Saint');
alpha3CountryCodes.set('VEN', 'Venezuela');
alpha3CountryCodes.set('VGB', 'Virgin');
alpha3CountryCodes.set('VIR', 'Virgin');
alpha3CountryCodes.set('VNM', 'Viet');
alpha3CountryCodes.set('VUT', 'Vanuatu');
alpha3CountryCodes.set('WLF', 'Wallis');
alpha3CountryCodes.set('WSM', 'Samoa');
alpha3CountryCodes.set('YEM', 'Yemen');
alpha3CountryCodes.set('ZAF', 'South');
alpha3CountryCodes.set('ZMB', 'Zambia');
alpha3CountryCodes.set('ZWE', 'Zimbabwe');
