// reference: http://lacpass.create.cl:8089/ValueSet-ddcc-vaccines.html
export const VACCINE_LIST = new Map();
VACCINE_LIST.set('XM68M6', 'COVID-19 vaccine');
VACCINE_LIST.set('XM1NL1', 'COVID-19 vaccine, inactivated virus');
VACCINE_LIST.set('XM7HT3', 'CoronaVac®');
VACCINE_LIST.set('XM8866', 'BBIBP-CorV');
VACCINE_LIST.set('XM9TQ1', 'KCONVAC');
VACCINE_LIST.set('XM1G90', 'Covaxin');
VACCINE_LIST.set('XM85P5', 'Covi-Vac');
VACCINE_LIST.set('XM9FQ7', 'Hayat-Vax');
VACCINE_LIST.set('XM97N6', 'QazVac');
VACCINE_LIST.set('XM2YG8', 'COVIran Barakat');
VACCINE_LIST.set('XM3U61', 'Inactivated SARS-CoV-2 vaccine');
VACCINE_LIST.set('XM5DF6', 'COVID-19 vaccine, live attenuated virus');
VACCINE_LIST.set('XM9QW8', 'COVID-19 vaccine, non-replicating viral vector');
VACCINE_LIST.set('XM4YL8', 'COVID-19 Vaccine AstraZeneca');
VACCINE_LIST.set('XM97T2', 'Covishield®');
VACCINE_LIST.set('XM6QV1', 'COVID-19 Vaccine Janssen');
VACCINE_LIST.set('XM1AG7', 'Convidecia');
VACCINE_LIST.set('XM5ZJ4', 'Gam-Covid-Vac');
VACCINE_LIST.set('XM5QM6', 'Sputnik-Light');
VACCINE_LIST.set('XM0CX4', 'COVID-19 vaccine, replicating viral vector');
VACCINE_LIST.set('XM5JC5', 'COVID-19 vaccine, virus protein subunit');
VACCINE_LIST.set('XM3CT4', 'Recombinant SARS-CoV-2 vaccine');
VACCINE_LIST.set('XM3PG0', 'Soberana-02');
VACCINE_LIST.set('XM4EC8', 'MVC-COV1901');
VACCINE_LIST.set('XM6SZ8', 'EpiVacCorona');
VACCINE_LIST.set('XM1J92', 'COVID-19 vaccine, virus like particle (VLP)');
VACCINE_LIST.set('XM6AT1', 'COVID-19 vaccine, DNA based');
VACCINE_LIST.set('XM52P3', 'ZyCov-D');
VACCINE_LIST.set('XM0GQ8', 'COVID-19 vaccine, RNA based');
VACCINE_LIST.set('XM8NQ0', 'Comirnaty®');
VACCINE_LIST.set('XM3DT5', 'COVID-19 Vaccine Moderna');
VACCINE_LIST.set('XM3418', 'Yellow fever, live attenuated');
VACCINE_LIST.set('XM28X5', 'Measles vaccines');
VACCINE_LIST.set('XM8L15', 'Measles, live attenuated');
VACCINE_LIST.set('XM9439', 'Measles, combinations with mumps, live attenuated');
VACCINE_LIST.set(
  'XM8TF3',
  'Measles, combinations with mumps and rubella, live attenuated',
);
VACCINE_LIST.set(
  'XM21H2',
  'Measles, combinations with rubella, live attenuated',
);
VACCINE_LIST.set(
  'XM4AJ8',
  'Measles, combinations with mumps, rubella and varicella, live attenuated',
);
VACCINE_LIST.set('XM0N50', 'Poliomyelitis vaccines');
VACCINE_LIST.set('XM0VX8', 'Poliomyelitis oral, monovalent live attenuated');
VACCINE_LIST.set('XM0KZ1', 'Poliomyelitis oral, trivalent, live attenuated');
VACCINE_LIST.set('XM79H3', 'Poliomyelitis oral, bivalent, live attenuated');
